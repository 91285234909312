<template>
  <v-container>
    <v-col cols="12">
      <base-material-card color="primary" icon="mdi-account-multiple-plus">
        <validation-observer ref="observer" v-slot="{ invalid }">
          <v-form @submit.prevent="create">
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6">
                <validation-provider rules="required" v-slot="{ errors }">
                  <v-text-field
                    v-model="payerName" :label="$t('create-payer.payer')" clearable/>
                  <span class="red--text font-weight-light">{{ errors[0] }}</span>
                </validation-provider>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <validation-provider rules="required" v-slot="{ errors }">
                  <v-autocomplete
                    v-model="agencyId" :items="agencies" :label="$t('create-payer.agency')" required
                    item-text="agencyName"
                    item-value="agencyId">
                  </v-autocomplete>
                  <span class="red--text font-weight-light">{{ errors[0] }}</span>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" lg="12">
                <v-text-field v-model="comment" :label="$t('create-payer.comments')" clearable/>
              </v-col>
            </v-row>
            <v-btn color="primary" @click="create" :disabled="invalid" block>
              {{$t('buttons.create-payer')}}
            </v-btn>
          </v-form>
        </validation-observer>
      </base-material-card>
    </v-col>
  </v-container>
</template>

<script>
  import {AXIOS} from "../../../http-commons";
  import {ValidationObserver, ValidationProvider, extend} from 'vee-validate';
  import {required} from 'vee-validate/dist/rules';
  import {useAgencies} from "../../stores/agenciesStore";
  import {usePayers} from "../../stores/payersStore";

  const payerStore = usePayers()
  const agencyStore = useAgencies()
  import {mapState} from "pinia";

  extend('required', {
    ...required,
    message: 'The field is required'
  });

  let moment = require('moment');

  export default {
    name: "Create Payer",
    components: {ValidationProvider, ValidationObserver},
    data() {
      return {
        payerName: '',
        agencyId: '',
        comment: ''
      }
    },
    methods: {
      create() {
        let action = 'create'
        let objectType = 'Payer'
        let params = new URLSearchParams();
        params.append('userId', this.currentUser.id);
        params.append('payerName', this.payerName);
        params.append('agencyId', this.agencyId);
        params.append('comment', this.comment);
        AXIOS.post(`/api/createPayer/`, params)
          .then(response => {
            payerStore.addPayer(response.data)
            this.userNotification(response, action, objectType);
            this.$router.push({path: '/payer/' + response.data.payerId})
          })
      },
      updateNeededCheck() {
        if (!this.agencies.length) {
          agencyStore.getAgencyList()
        }
      },
    },
    computed: {
      ...mapState(useAgencies, ['agencies']),
      ...mapState(usePayers, ['payers']),
    },
    created() {
      this.updateNeededCheck()
    },
  }
</script>

<style scoped>
  .red--text {
    font-size: 12px;
  }
</style>
